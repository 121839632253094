.upload {
  height: 100%;
  width: 100%;

  padding: 40px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 6%;
}

.upload-start-info {
  width: 100%;
  height: 100%;
  display: flex;

  align-items: stretch;
  flex-direction: row;
}

.upload-start-info-half {
  width: 45%;
  margin-left: 16px;
  margin-right: 16px;
}

.upload-info-header {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 14px;
  margin-bottom: 38px;
}

.upload-cropper {
  position: relative;
  z-index: 16;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: white;

  width: clamp(380px, 60%, 600px);
  height: 100%;
}

.upload-cropper p {
  width: 95%;
  margin-bottom: 8px;
  text-align: start;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.upload-cropping-container {
  position: relative;

  height: 90%;
  width: 100%;
  border-radius: 4px;
  border: 1px solid gray;
  background: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.upload-cropper-done {
  width: 98%;
  height: 64px;
  margin-top: 8px;
  margin-bottom: 6%;
  outline: none;
  border: 0;
  border-radius: 4px;
  padding: 12px 20px;
  color: white;
  background: rgb(233, 172, 81);
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
}

.upload-form {
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  border: 3px dashed rgb(90, 90, 90);
  background: rgba(0, 0, 0, 0.08);
}

.upload-form input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.upload-form p {
  text-align: center;
  margin-top: 8px;
  font-weight: 700;
  color: rgb(70, 70, 70);
}

.upload-icon {
  height: 150px;
  width: 100%;
  color: gray;
}

.upload-image-container {
  width: 100%;
  height: 500px;
  border: 2px solid rgba(90, 90, 90, 0.4);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.upload-image {
  margin: 0px 6px 0px 6px;
  max-height: 90%;
  width: 115px;
  max-width: 20%;
  border-radius: 4em;
  object-fit: cover;
}

.upload-image-button {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 4px;
  padding: 12px 20px;
  margin-top: 6px;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
}

.upload-button-container {
  margin-top: auto;
}

.upload-image-button-container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.upload-complete-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4%;
}

.upload-complete-container p {
  width: 70%;
  margin-top: 30px;
  text-align: center;
  line-height: 1.6em;
}

.upload-complete-button-container {
  margin-top: 34px;
  display: flex;
}

.upload-complete-button-container button {
  margin: 0px 6px 0px 6px;
  outline: none;
  border: 0;
  border-radius: 2px;
  padding: 12px 20px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  background: rgb(182, 182, 182);
  transition: all 0.2s;
}

.upload-complete-button-container button:hover {
  background: rgb(163, 163, 163);
}

.upload-complete-icon {
  height: 25%;
  width: 25%;
  color: rgb(133, 204, 133);
}

.upload-info-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.upload-info-container-half {
  margin-left: 12px;
  margin-right: 12px;
  width: 50%;
}

.upload-loading {
  align-self: center;
  justify-self: center;
}

#upload-image-button-edit {
  background: rgb(233, 172, 81);
}

#upload-image-button-delete {
  background: rgb(228, 90, 90);
}

#upload-image-button-done {
  background: rgb(135, 228, 117);
}

#info-half {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#info-half h2 {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 14px;
  margin-bottom: 8%;
}

#info-half p {
  margin-bottom: 18%;
}

#info-half label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

#info-half input {
  outline: none;
  display: block;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 20px;
  margin-bottom: 6px;
  color: rgb(70, 70, 70);
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
}

#upload-info-half h4 {
  margin-bottom: 16px;
  margin-top: 38px;
}

#upload-info-half h4:first-child {
  margin-top: 0px;
}

#upload-info-half p {
  margin-bottom: 8px;
}

#upload-complete-designs {
  background: white;
  border: 1px solid rgb(182, 182, 182);
}

#upload-complete-designs:hover {
  background: rgb(163, 163, 163);
}

@media screen and (max-width: 768px) {
  .upload-start-info {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .upload-start-info-half {
    width: 100%;
    margin-bottom: 36px;
  }

  .upload-info-container {
    flex-direction: column;
  }

  .upload-info-container-half {
    margin-bottom: 8%;
    width: 100%;
  }

  .upload-complete-button-container {
    flex-direction: column;
  }

  .upload-complete-button-container button {
    margin-bottom: 6px;
  }

  .upload-complete-container p {
    font-size: 0.9em;
  }
  .upload-complete-icon {
    width: 100%;
  }
}
