.warning-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.warning-container {
  background: white;
  max-width: 800px;
  width: 40%;
  padding: 24px;
  border-radius: 4px;
  z-index: 17;
}

.warning-container h2 {
  margin-bottom: 16px;
}

.warning-container p {
  margin-bottom: 28px;
}

.warning-container button {
  min-width: 16%;
  height: 36px;
  margin: 4px;
  border: none;
  border-radius: 3px;
  font-weight: 700;
  cursor: pointer;
}

#cancel {
  background: rgb(233, 172, 81);
}

#continue {
  background: rgb(228, 90, 90);
}
