.dashboard {
  height: 100vh;
  width: 100vw;
}

.dashboard-content {
  height: 100%;
  width: 100%;

  display: flex;
}

.dashboard-page {
  width: 100%;
  height: 100vh;
  margin-left: 240px;
}
