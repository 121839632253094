.sidebar {
  min-height: 100vh;
  width: 240px;
  background: rgb(47, 64, 80);
  align-self: flex-start;
  position: fixed;
  top: 0px;
}

.sidebar-logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20%;
  cursor: pointer;
}

.sidebar-logo-container h3 {
  color: white;
  letter-spacing: 0.06em;
}

.sidebar-logo {
  margin: 20px;
}

.sidebar-list {
  height: auto;
  padding: 0;
  width: 100%;
}

.sidebar-row {
  width: 100%;
  height: 60px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  transition: all linear 0.1s;
}

.sidebar-row:hover {
  cursor: pointer;
  background: rgb(41, 55, 69);
}

.sidebar-row #icon {
  flex: 30%;
  display: grid;
  place-items: center;
  font-size: 1.4em;
}

.sidebar-row #title {
  flex: 70%;
}

#sidebar-active {
  background: rgb(41, 55, 69);
}
