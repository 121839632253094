.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;
}

.about-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 46px;
}

.about-title h2 {
  font-size: 2.4rem;
}

.about-title-separator {
  background: rgb(238, 172, 74);
  width: 150px;
  height: 2px;
  margin: 1rem 0;
  padding: 0;
}

.about-subtitle {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.8);
  padding-bottom: 1.5rem;
  text-align: center;
}

.about-info-container {
  width: 60%;
  margin-bottom: 4%;
}

.about-info-container p {
  padding-bottom: 1.5rem;
  font-size: 1.2rem;
  color: #555;
  line-height: 1.9rem;
}
