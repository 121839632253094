* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 55cm;
}

.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-logo {
  width: 8vw;
  max-width: fit-content;
  height: auto;
  position: fixed;
  top: 36px;
  left: 14px;
  z-index: 100;
}
