h1 {
  font-size: 4.5em;
  font-weight: 800;
  color: rgb(238, 172, 74);
  letter-spacing: 0.12em;
  transition: 0.25s;
  text-align: center;
  cursor: default;

  width: inherit;
  min-width: 4.6em;
  width: 64%;
}

.hero-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;

  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.hero-half {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: stretch;

  width: 34%;
  height: 100%;
  margin: 0 60px 0 60px;

  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: column;

  margin-bottom: 40px;

  justify-content: start;
  align-items: center;
  text-align: center;
}

.description {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  text-align: justify;
  line-height: 1.6em;
  max-width: 75%;
  align-self: center;
}

.btn-container {
  margin: 80px 0 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  height: 50px;
  width: 39%;

  padding: 20px 40px;
  margin: 0 20px 0 20px;

  position: relative;

  background: none;
  border: none;

  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;

  cursor: pointer;
}

.hero-image {
  margin-top: 40px;
  min-height: 100vh;
  background: url("../../images/Astronaut.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#h1-your {
  color: white;
  background-color: black;
}

#learn-more::after {
  background: none;
}

h1:hover {
  letter-spacing: 0.25em;
}

h1:focus {
  letter-spacing: 0.25em;
}

@media screen and (max-width: 1550px) {
  .description {
    max-width: 100%;
  }

  .btn-container {
    flex-direction: column;
    margin: 56px 0 60px 0;
  }

  .btn {
    width: 85%;
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 1000px) {
  .hero-image-half {
    display: none;
  }

  .hero-header-half {
    width: auto;
  }

  .hero-container {
    margin-top: 3vh;
  }

  h1 {
    width: inherit;
  }

  .description {
    max-width: 85%;
  }

  .btn-container {
    flex-direction: row;
    margin: 80px 0 60px 0;
  }

  .btn {
    width: 39%;
    margin: 0 20px 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .btn-container {
    flex-direction: column;
    margin: 56px 0 60px 0;
  }

  .btn {
    width: 85%;
    margin-bottom: 26px;
  }

  .hero-container {
    margin-top: 7vh;
  }
}
