.design-details {
  height: 100vh;
  width: 100%;

  padding: 40px;

  display: flex;
  flex-direction: row;
}

.design-details-half {
  height: 100%;
  width: 50%;
  margin: 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.design-details-half img {
  margin: 0px 6px 0px 6px;
  height: 90%;
  max-width: 45%;
  border-radius: 10em;
  object-fit: cover;
}

.design-details-date {
  font-size: medium;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10%;
}

.design-details-sales {
  font-weight: 700;
  font-size: 1.3em;
}

.designs-details-list-element-status {
  margin-top: 24px;
  margin-bottom: 36px;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.designs-details-list-element-status p {
  font-weight: 500;
  line-height: 100%;
}

.designs-details-list-element-status div {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 16px;
}

.design-details-buttons {
  width: 80%;
  margin-bottom: 3%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.design-details-buttons button {
  width: 100%;
  height: 64px;
  margin-top: 6px;
  outline: none;
  border: 0;
  border-radius: 4px;
  padding: 12px 20px;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
}

.design-details-buttons #take-offline {
  background: rgb(233, 172, 81);
}

.design-details-buttons #delete {
  background: rgb(228, 90, 90);
}

.design-details-buttons #set-online {
  background: rgb(135, 228, 117);
}

.design-details-back-container {
  height: 100%;
}

.design-details-back-container button {
  margin-right: 12px;
  background: none;
  border: none;
  font-size: medium;
  color: mediumblue;
  cursor: pointer;
}

.design-details-back-container button:hover {
  text-decoration: underline;
}

#desing-details-img-half {
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.08);

  align-items: center;
  justify-content: center;
  flex-direction: row;
}

#design-details-content-half {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#design-details-content-half h2 {
  font-size: 2em;
  margin-bottom: 14px;
  overflow-wrap: break-word;
  hyphens: auto;
}

@media screen and (max-width: 1200px) {
  .design-details {
    flex-direction: column;
  }
  .design-details-half {
    width: 100%;
  }

  #desing-details-img-half {
    width: 100%;
  }
}
