.headline {
  background: rgba(255, 122, 122, 0.664);
  padding: 4px;
  width: 100%;
  text-align: center;
}

.headline p {
  color: rgb(59, 59, 59);
  font-weight: 600;
  font-size: clamp(0.6em, 1vw, 1em);
}
