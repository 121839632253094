.account {
  height: 100vh;
  width: 100%;

  padding: 4%;

  display: flex;
  flex-direction: column;
}

.account h2 {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 14px;
}

.account h3 {
  margin-bottom: 24px;
}

.account-info-container {
  width: 40%;
  margin-left: 18px;
}

.account-info-edit-container {
  margin-bottom: 6%;
  padding-bottom: 12%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.account-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: 100%;
  overflow: hidden;
  padding: 12px;
}

.account-about {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: 100%;
  padding: 16px;
  resize: none;
}

.account-bar-container button {
  min-width: 20%;
  outline: none;
  border: 0;
  border-radius: 2px;
  padding: 12px 20px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  background: rgb(182, 182, 182);
  transition: all 0.2s;
}

.account-bar-container button:hover {
  background: rgb(163, 163, 163);
}

.account-bar-heading {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.85em;
  color: rgba(0, 0, 0, 0.6);
}

.account-bar-info {
  font-size: 1.1em;
}

.account-edit-button {
  outline: none;
  border: 0;
  border-radius: 2px;
  padding: 12px 20px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  background: rgb(182, 182, 182);
  transition: all 0.2s;
}

.account-edit-button:hover {
  background: rgb(163, 163, 163);
}

.account-sign-out-container button {
  margin-right: 8px;
  margin-bottom: 8px;
  border: none;
  border-radius: 2px;
  padding: 12px 20px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  background: none;
  transition: all 0.2s;
}

#account-sign-out {
  border: 1px solid rgb(230, 71, 71);
}

#account-delete {
  background: rgb(230, 71, 71);
}

#account-sign-out:hover {
  background: rgba(230, 71, 71, 0.8);
  border: 1px solid rgba(230, 71, 71, 0.8);
}

#account-delete:hover {
  background: rgba(230, 71, 71, 0.8);
}

@media screen and (max-width: 768px) {
  .account-info-container {
    width: 90%;
  }
  .account-bar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .account-bar-container button {
    margin-top: 18px;
    width: 100%;
  }
}
