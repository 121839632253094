.btn::after {
  content: "";

  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: -1;

  background: rgb(238, 172, 74);
  border: 2px solid black;

  transform: translate(3px, 3px);
  transition: transform 0.2s;
}

.btn::before {
  content: "";

  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  border: 2px solid black;

  transform: translate(-3px, -3px);
  transition: transform 0.2s;
}

.btn:hover::before {
  transform: translate(0);
}

.btn:hover::after {
  transform: translate(0);
}
