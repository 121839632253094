.modal {
  position: fixed;
  z-index: 16;

  width: min(600px, 80%);

  background: white;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 100px auto 10px;
  overflow: hidden;
}

.auth-modal-layout {
  padding: 10%;
  padding-bottom: 5%;
}

.auth-modal-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 20px;
}

.auth-modal-header {
  padding: 4px 0;
  color: rgb(66, 133, 244);
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;

  margin-bottom: 1.2rem;
}

.auth-modal-lable {
  display: block;
  margin: 15px 0 10px;
  color: gray;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.auth-modal-input {
  outline: none;
  display: block;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 20px;
  color: rgb(70, 70, 70);
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  margin-top: 12px;
  margin-bottom: 12px;
}

.auth-modal-button {
  outline: none;
  background: rgb(66, 133, 244);
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: 12px 20px;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
  margin: 1.5rem 0 5px 0;
}

.auth-modal-recovery {
  color: rgb(67, 94, 244);
  font-size: 13px;
  text-decoration: none;

  width: 100%;
  text-align: end;

  cursor: pointer;
}

.auth-modal-switch {
  color: rgb(67, 85, 244);
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.auth-recover-layout {
  padding: 7%;
  width: 100%;
}

.auth-recover-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.auth-recover-layout h2 {
  margin-top: 36px;
  margin-bottom: 16px;
}

.auth-recover-container p {
  margin-bottom: 24px;
}

.auth-recover-container button {
  margin-right: 12px;
  margin-bottom: 24px;
  outline: none;
  border: 0;
  border-radius: 2px;
  padding: 12px 20px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  background: rgb(182, 182, 182);
  transition: all 0.2s;
}

.auth-recover-container button:hover {
  background: rgb(163, 163, 163);
}

.auth-recover-button-container {
  margin-top: 26px;
}

.auth-change-auth-mode {
  cursor: default;
}
.auth-change-auth-mode a {
  cursor: pointer;
  color: blue;
}
.auth-change-auth-mode a:hover {
  text-decoration: underline;
}
.auth-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#auth-recover-done-button {
  margin-top: 42px;
  background: rgb(233, 172, 81);
}

#auth-recover-done-button:hover {
  background: rgb(221, 182, 122);
}

#auth-recover-email {
  font-weight: 600;
}

#auth-recover-counter {
  font-weight: 600;
}
