.error-container {
  position: fixed;
  top: 20px;
  width: min(620px, 80%);
  background: rgb(255, 98, 86);
  border-radius: 6px;
  padding: 30px;
  transition: all 300ms linear;
  z-index: 10;
}

.error-container p {
  color: white;
}

@media screen and (max-width: 768px) {
  .error-container {
    left: 10px;
    width: 90%;
  }
}
