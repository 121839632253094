.designs {
  height: 100vh;
  width: 100%;

  padding: 40px;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.designs-list {
  height: 90%;
  width: 80%;
  background: rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  overflow-x: hidden;
}

.designs-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 12px;
}

.designs-list-sort-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.designs-list-sort-container select {
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}

.designs-list-sort-container p {
  font-size: 16px;
}

.designs-list-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  margin-bottom: 4px;
  padding: 0 6% 0 6%;
  cursor: pointer;
  overflow-y: hidden;
}

.designs-list-element-sales {
  width: 20%;
  font-weight: 500;
}

.designs-list-element-status {
  width: 20%;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.designs-list-element-status p {
  font-weight: 500;
  line-height: 100%;
}

.designs-list-element-status div {
  height: 12px;
  width: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  margin-right: 16px;
}

.designs-list-element-title {
  margin-left: 0%;
  width: 40%;
  overflow-y: hidden;
}

.designs-list-element-title p {
  font-size: small;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
}

.designs-list-element-title h2 {
  margin-bottom: 8px;
  overflow-wrap: break-word;
  hyphens: auto;
}

.designs-list-element img {
  height: 90%;
  border-radius: 4em;
}

.designs-list-empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: auto;
}

.designs-list-empty-container p {
  margin-top: 24px;
  text-align: center;
  font-weight: 600;
}

.designs-list-empty-container p a {
  color: blue;
  cursor: pointer;
}

.designs-list-empty-container p a:hover {
  text-decoration: underline;
}

.designs-list-empty-icon {
  height: 50%;
  width: 50%;
  color: rgba(0, 0, 0, 0.5);
}

.designs-note {
  justify-self: flex-end;
  text-align: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  width: 60%;
  font-size: 0.7em;
}

#red {
  background: red;
}

#yellow {
  background: orange;
}

#green {
  background: green;
}

@media screen and (max-width: 768px) {
  .designs-list-header {
    flex-direction: column;
  }
  .designs-list-header h2 {
    margin-bottom: 18px;
  }
  .designs-list-element {
    justify-content: flex-start;
  }
  .designs-list-element-title {
    width: auto;
    margin-left: 6px;
  }
  .designs-list-element-status,
  .designs-list-element-sales {
    display: none;
  }

  .designs-list-empty-icon {
    width: 100%;
  }

  .designs-list-empty-container p {
    font-size: 0.9em;
  }
  .designs-note {
    display: none;
  }
}
